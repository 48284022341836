import React from 'react'
import Video from './Video'
import Img from 'gatsby-image'

import cx from 'classnames'

const ProjectAsset = ({ asset }) => {
	const { projectAsset, isFullScreen, isCurrent, projectType } = asset

	const { poster } = projectAsset

	const assetFile =
		projectType === 'photo' ? (
			<aside
				className={cx('img-assets', {
					'two-image-flex': asset.projectAsset.photoLayout === 'two-image-flex'
				})}
				style={{
					backgroundImage: isFullScreen ? `url(${projectAsset.image[0].asset.url})` : null,
				}}>
				{projectAsset.image.map((img, index) => {
					return (
						<div
							key={img.asset.id}
							className={cx(`img-asset ${asset.projectAsset.photoLayout}`, {
								mobile: index === 0,
								fullscreen: asset.fullscreen,
							})}>
							<img src={img.asset.url} alt={asset.alt} />
						</div>
					)
				})}
			</aside>
		) : (
			<Video
				src={projectAsset.video.asset.url}
				className={cx(`video`, {
					fullscreen: isFullScreen,
				})}
				isCurrent={isCurrent}
				poster={poster}
			/>
		)

	return (
		<>
			<section
				className={cx('project-asset', {
					fullscreen: isFullScreen,
					current: isCurrent,
				})}>
				{assetFile}
			</section>
		</>
	)
}
export default ProjectAsset
