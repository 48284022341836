import React, { useRef, useEffect } from 'react'

const Video = ({ isCurrent, src, className, poster }) => {
	const videoRef = useRef()

	useEffect(() => {
		if (typeof window !== 'undefined' && typeof document !== 'undefined') {
			const Plyr = require('plyr')
			const player = new Plyr(videoRef.current, {
				muted: true,
				loop: {
					active: true,
				},
				autoplay: false,
				hideControls: true,
				clickToPlay: false,
			})
		}
	}, [])

	useEffect(() => {
		if (videoRef.current) {
			if (isCurrent) {
				videoRef.current.focus()
				videoRef.current.play()
			} else {
				videoRef.current.pause()
			}
		}
		return () => {
			videoRef.current.pause()
		}
	}, [isCurrent])

	return <video ref={videoRef} src={src} type='video/mp4' playsInline loop muted controls={false} className={className} data-poster={poster?.asset?.url} poster={poster?.asset?.url} />
}
export default Video
